import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Container, Row, Col, Card, Button, Spinner } from 'react-bootstrap';
import useProductApi, { Product } from '../../../managers/ProductManager';
import { useShoppingCart } from '../../../context/ShoppingCartContext';
import { formatCurrency } from '../../../utilities/formatCurrency';
import Loading, { LoadingStates } from '../../../components/Loading';
import ProductDetail from './ProductDetail';

interface ProductListProps {
  userId?: string | null; // Make `userId` optional and allow null
}

const ProductList: React.FC<ProductListProps> = ({ userId }) => {
  const [loadingState, setLoadingState] = useState<typeof LoadingStates[keyof typeof LoadingStates]>(LoadingStates.LOADING);
  const [deleting, setDeleting] = useState<{ [key: string]: boolean }>({});
  const [loadingMessage, setLoadingMessage] = useState("Loading");
  const { getProducts, getUserProducts, deleteProduct } = useProductApi();
  const [products, setProducts] = useState<any[]>([]);
  const lastKeyRef = useRef<Record<string, any> | null>(null); // Persistent pagination key
  const isFetching = useRef(false); // Prevents multiple fetches at the same time
  const hasMoreProducts = useRef(true); // Tracks whether there are more products to load
  const observer = useRef<IntersectionObserver | null>(null); // Holds the IntersectionObserver

  const [selectedProduct, setSelectedProduct] = useState<any | null>(null); // For the modal
  const [isModalOpen, setModalOpen] = useState(false);

  const {
    getItemQuantity,
    addToCart,
    removeFromCart,
    openCart,
  } = useShoppingCart();

  // Fetch products function
  const fetchProducts = useCallback(async () => {
    if (isFetching.current || !hasMoreProducts.current) return;

    isFetching.current = true; // Prevents concurrent fetches
    setLoadingState(LoadingStates.LOADING);
    try {
      const data = userId
        ? await getUserProducts(userId, 10, lastKeyRef?.current || undefined)
        : await getProducts(10, lastKeyRef?.current || undefined);

      if (data.items.length === 0) {
        hasMoreProducts.current = false; // Stop further requests
      } else {
        setProducts((prevProducts) => [...prevProducts, ...data?.items]);

        if (data.lastKey) {
          lastKeyRef.current = data.lastKey; // Update pagination key persistently
        } else {
          hasMoreProducts.current = false; // Stop further fetches if no `lastKey`
        }
      }
      setLoadingState(LoadingStates.SUCCESS);
    } catch (error) {
      hasMoreProducts.current = false;
      setLoadingState(LoadingStates.ERROR);
      setLoadingMessage("Error fetching products: " + error);
      console.error("Error fetching products:", error);
    } finally {
      isFetching.current = false;
    }
  }, [lastKeyRef.current, getProducts]);

  // Effect to fetch initial products when component mounts
  useEffect(() => {
    if (products.length === 0) {
      fetchProducts(); // Fetch initial products when the component mounts
    }
  }, [fetchProducts]);

  const openProductModal = (product: any) => {
    setSelectedProduct(product);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedProduct(null);
  };

  const handleDeleteProduct = async (productId: string) => {
    if (!userId) {
      console.error("User ID is required to delete a product.");
      return;
    }
    setDeleting((prevState) => ({ ...prevState, [productId]: true }));
    try {
      await deleteProduct(productId, userId);
      setProducts((prevProducts) => prevProducts.filter((product) => product.productId !== productId));
      console.log(`Product ${productId} deleted successfully.`);
    } catch (error) {
      console.error("Error deleting product:", error);
    } finally {
      setDeleting((prevState) => ({ ...prevState, [productId]: false }));
    }
  };

  const handleAddToCart = async (product: any) => {
    addToCart({
      productId: product?.productId,
      productUserId: product?.userId,
      name: product.name,
      description: product?.catalogItemVariantName,
      catalogItemId: product?.catalogItemId,
      catalogItemVariantId: product?.catalogItemVariantId,
      price: product?.price,
      quantity: 1,
      metadata: { ...product?.metadata, previewImageUrls: product?.previewImageUrls },
    });
  }

  // IntersectionObserver callback for the last product
  const lastProductElementRef = useCallback((node: HTMLDivElement | null) => {
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isFetching.current && hasMoreProducts.current) {
          fetchProducts(); // Fetch more products when the last product is visible
        }
      },
      {
        root: null,
        rootMargin: "200px", // Trigger earlier for smoother loading
        threshold: 0,
      }
    );

    if (node) observer.current.observe(node);
  }, [fetchProducts]);

  if ((loadingState === LoadingStates.LOADING || loadingState === LoadingStates.ERROR) && products.length === 0) {
    return <div><Loading state={loadingState} message={loadingMessage} /></div>;
  }

  if (!products || products.length === 0) {
    return <div>No products found.</div>;
  }

  return (
    <Container>
      <Row>
        {products.map((product, index) => {
          const quantity = getItemQuantity(product.productId);
          return (
            <Col
              key={index}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              ref={products.length === index + 1 ? lastProductElementRef : null}
            >
              <Card className="mb-2">
                <Card.Img
                  variant="top"
                  src={product.previewImageUrls?.[0]}
                  alt={`Product ${index}`}
                  loading="lazy"
                  className="img-fluid ws-img-clickable"
                  onClick={() => openProductModal(product)}
                />
                <Card.Body>
                  <Card.Title className="d-flex justify-content-between align-items-baseline mb-3">
                    <span className="fs-6 fst-italic text-center">{product.name || product.title}</span>
                    <span className="ms-2 text-muted">{formatCurrency(product?.price)}</span>
                  </Card.Title>
                  <div className="mt-auto">
                    <div className="d-flex align-items-center flex-column" style={{ gap: ".5rem" }}>
                      {quantity === 0 ? (
                        <Button className="w-100" onClick={() => handleAddToCart(product)}>
                          + Add To Cart
                        </Button>
                      ) : (
                        <Button
                          onClick={() => removeFromCart(product.productId)}
                          className="w-100"
                          variant="secondary"
                        >
                          - Remove From Cart
                        </Button>
                      )}
                      <Button
                        onClick={() => openCart()}
                        className="w-100"
                        variant="outline"
                        size="sm"
                      >
                        View Cart
                      </Button>
                      {(product.userId !== "0") &&
                        // Button with Spinner
                        <Button
                          onClick={() => handleDeleteProduct(product.productId)}
                          className="w-100"
                          color="danger"
                          size="sm"
                          disabled={deleting[product.productId]} // Disable button while loading
                        >
                          {deleting[product.productId] ? (
                            <>
                              <Spinner size="sm" className="me-2" />
                              Deleting...
                            </>
                          ) : (
                            'Delete'
                          )}
                        </Button>
                      }
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
      {selectedProduct && (
        <ProductDetail
          isModalOpen={isModalOpen}
          toggleModal={closeModal}
          product={selectedProduct}
          onAddToCart={() => { handleAddToCart(selectedProduct); closeModal(); }}
        />
      )}
    </Container>
  );
};

export default ProductList;
