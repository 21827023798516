import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { getEnvironment } from './EnvironmentManager';

interface ProductRequestParams {
  userId: string,
  sourceImageUrl: string;
  sourceImageId: string;
  catalogItemId: string;
  catalogItemVariantId: string;
}

interface ApiUrls {
  [key: string]: string;
}

const productApiUrls: ApiUrls = {
  localhost: 'http://127.0.0.1:5000/products/recent',
  dev: 'https://api.wallscapes.ai/dev/product',
  prod: 'https://api.wallscapes.ai/prod/product'
};

const getProductApiUrl = () => {
  const environment = getEnvironment();
  return productApiUrls[environment];
};

export class Product {
  productId?: string;
  userId?: string;
  createdDate?: string;
  updatedDate?: string;

  name?: string;
  description?: string;
  sourceImageId?: string;
  previewImageKeys?: string[];
  previewImageUrls?: string[];
  catalogItemId?: string;
  catalogItemName?: string;
  catalogItemDescription?: string;
  catalogItemVariantId?: string;
  catalogItemVariantName?: string;
  price?: number;
  vendorName?: string;
  vendorData?: any;
  tags?: string[];
}

const useProductApi = () => {
  const { getAccessTokenSilently } = useAuth0();

  const generateProduct = async (params: ProductRequestParams): Promise<Product> => {
    const endpointUrl = getProductApiUrl();
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.post(endpointUrl, params, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        }
      });

      if (response?.status === 200 && response?.data?.status) {
        return response.data.data;
      } else {
        throw new Error('No image URL found in the response or status is not success.');
      }
    } catch (error) {
      console.log('Error generating product image:', JSON.stringify(error));
      throw new Error('Failed to retrieve image URL from the API');
    }
  };

  const getProducts = async (limit: number = 10, lastKey?: Record<string, any>): Promise<{ items: Array<Object>, lastKey: Record<string, any> | null }> => {
    const params = new URLSearchParams({
      limit: limit.toString(),
      lastKey: lastKey
        ? JSON.stringify(lastKey) // Encode the lastKey as a JSON string
        : "",
    });

    const endpointUrl = `${getProductApiUrl()}?${params.toString()}`;
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.get(endpointUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        }
      });

      if (response?.status === 200 && response?.data?.status === 'success' && response?.data?.data) {
        return response.data.data;
      } else {
        throw new Error('No product data found in the response or status is not success.');
      }
    } catch (error) {
      console.error('Error calling API:', JSON.stringify(error));
      throw new Error('Failed to retrieve product data from the API');
    }
  };

  const getUserProducts = async (userId: string = "0", limit: number = 10, lastKey?: Record<string, any>): Promise<{ items: Array<Object>, lastKey: Record<string, any> | null }> => {
    const params = new URLSearchParams({
      userId: userId,
      limit: limit.toString(),
      lastKey: lastKey
        ? JSON.stringify(lastKey) // Encode the lastKey as a JSON string
        : "",
    });

    const endpointUrl = `${getProductApiUrl()}?${params.toString()}`;
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.get(endpointUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        }
      });

      if (response?.status === 200 && response?.data?.status === 'success' && response?.data?.data) {
        return response.data.data;
      } else {
        throw new Error('No product data found in the response or status is not success.');
      }
    } catch (error) {
      console.error('Error calling API:', JSON.stringify(error));
      throw new Error('Failed to retrieve product data from the API');
    }
  };

  const getProduct = async (productId: string): Promise<Product> => {
    const endpointUrl = `${getProductApiUrl()}/${productId}`;
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.get(endpointUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        }
      });
      if ((response?.status === 200) && response?.data?.data) {
        return response.data.data as Product;
      } else {
        throw new Error('No product data found in the response or status is not success.');
      }
    } catch (error: any) {
      console.error('Error calling API:', error?.message);
      throw new Error('Failed to retrieve product data from the API');
    }
  };

  /**
   * Delete a product by ID for the given userId
   * @param {string} productId - ID of the product to delete
   * @param {string} userId - ID of the user that owns the product
   * @returns {Promise<void>} - Resolves when the product is deleted
   */
  const deleteProduct = async (productId: string, userId: string): Promise<void> => {
    const endpointUrl = `${getProductApiUrl()}/${productId}?userId=${encodeURIComponent(userId)}`;
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.delete(endpointUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      if (response?.status !== 200 || response?.data?.status !== 'success') {
        throw new Error('Failed to delete the product. Response status is not success.');
      }
    } catch (error) {
      console.error('Error deleting product:', JSON.stringify(error));
      throw new Error('Failed to delete the product.');
    }
  };

  return { generateProduct, getProducts, getUserProducts, getProduct, deleteProduct };
};

export default useProductApi;
