import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { getEnvironment } from './EnvironmentManager';

interface ImageRequestParams {
  userId: string;
  action: string;
  prompt: string;
  imageShape: string;
  imageStyle: string;
  vendorId: string;
}

interface ApiUrls {
  [key: string]: string;
}
const imageApiUrls: ApiUrls = {
  localhost: 'http://127.0.0.1:5000/image',
  dev: 'https://api.wallscapes.ai/dev/image',
  prod: 'https://api.wallscapes.ai/prod/image'
};

const getImageApiUrl = () => {
  const environment = getEnvironment();
  return imageApiUrls[environment];
};

interface ImageBaseUrls {
  [key: string]: string;
}
const imageBaseUrls: ImageBaseUrls = {
  localhost: 'https://dev-images.wallscapes.ai/',
  dev: 'https://dev-images.wallscapes.ai/',
  prod: 'https://images.wallscapes.ai/',
};

const useImageApi = () => {
  const { getAccessTokenSilently } = useAuth0();

  const generateImage = async (params: ImageRequestParams): Promise<any> => {
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.post(getImageApiUrl(), params, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        }
      });

      if (response?.status === 200 && response?.data?.status) {
        return response.data.data;
      } else {
        throw new Error('No image URL found in the response or status is not success.');
      }
    } catch (error) {
      console.error('Error calling API:', JSON.stringify(error));
      throw new Error('Failed to retrieve image URL from the API');
    }
  };

  /**
   * Upload a user-provided image.
   * @param {Object} params - Parameters for user image upload.
   * @param {string} params.userId - The ID of the user uploading the image.
   * @param {string} params.action - ex: 'upload'.
   * @param {string} params.imageName - The name of the image.
   * @param {string} params.prompt - The modification prompt.
   * @param {string} params.base64Image - The base64-encoded image data.
   * @returns {Promise<Object>} - Uploaded image metadata.
   */
  const uploadImage = async (params: { userId: string; action: string; imageName: string; prompt: string; base64Image: string }) => {
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.post(getImageApiUrl(), params, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      if (response?.status === 200 && response?.data?.status) {
        return response.data.data;
      } else {
        throw new Error('No image URL found in the response or status is not success.');
      }
    } catch (error) {
      console.error('Error uploading user image:', error);
      throw new Error('Failed to upload user image.');
    }
  };

  const getImages = async (limit: number = 10, lastKey?: Record<string, any>): Promise<{ items: Array<Object>, lastKey: Record<string, any> | null }> => {
    const params = new URLSearchParams({
      limit: limit.toString(),
      lastKey: lastKey
        ? JSON.stringify(lastKey) // Encode the lastKey as a JSON string
        : "",
    });

    const endpointUrl = `${getImageApiUrl()}?${params.toString()}`;
    try {
      const response = await axios.get(endpointUrl, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response?.status === 200 && response?.data?.status === 'success' && response?.data?.data) {
        return response.data.data;
      } else {
        throw new Error('No image data found in the response or status is not success.');
      }
    } catch (error) {
      console.error('Error calling API:', JSON.stringify(error));
      throw new Error('Failed to retrieve image data from the API');
    }
  };

  /**
   * Get the base image url for the environment
   */
  const getImageBaseUrl = () => {
    const environment = getEnvironment();
    return imageBaseUrls[environment];
  };

  return { generateImage, uploadImage, getImages, getImageBaseUrl };
};

export default useImageApi;
