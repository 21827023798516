import React from 'react';
import {
  Button, Container, Row, Col,
  Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  RedditShareButton,
  PinterestShareButton,
} from "react-share";
import {
  FaFacebook, FaXTwitter, FaWhatsapp, FaReddit, FaPinterest, FaEnvelope,
  FaRegCopy, FaInstagram
} from "react-icons/fa6"; // Import FontAwesome icons
import { toast, ToastContainer } from "react-toastify"; // Import Toastify
import "react-toastify/dist/ReactToastify.css"; // Import toast styles
import { Product } from '../../../managers/ProductManager';
import { formatCurrency } from '../../../utilities/formatCurrency';

interface ProductDetailProps {
  isModalOpen: boolean;
  toggleModal: () => void;
  product: Product;
  onAddToCart: () => void;
}

const ProductDetail: React.FC<ProductDetailProps> = ({
  isModalOpen,
  toggleModal,
  product,
  onAddToCart,
}) => {
  const productUrl = window.location.href; // Use current page URL for sharing
  const siteName = "Wallscapes"; // Example site name for reference in shares
  const productImgUrl = product.previewImageUrls?.[0] || ''; // TODO: put in an image not found image

  // Function to Copy Link to Clipboard with Toast
  const copyToClipboard = async (message: string) => {
    try {
      await navigator.clipboard.writeText(productUrl);
      toast.success(message, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      console.error("Failed to copy link:", error);
      toast.error("Failed to copy link.");
    }
  };

  // Construct mailto link for email sharing
  const mailtoLink = `mailto:?subject=${encodeURIComponent(
    `Check out this product from ${siteName}`
  )}&body=${encodeURIComponent(
    `Hey,\n\nI found this amazing product on ${siteName} and thought you'd like it!\n\nCheck it out: ${productUrl}\n\nBest,\n[Your Name]`
  )}`;

  const SHARE_ICON_SIZE = 26;

  return (
    <>
      {/* Ensure ToastContainer is present */}
      <ToastContainer />

      <Modal isOpen={isModalOpen} toggle={toggleModal} fullscreen>
        <ModalHeader toggle={toggleModal}>
          <Row className="justify-content-center">
            <Col md={12} className="text-center"> <h3>{product?.name}</h3></Col>
          </Row>
        </ModalHeader>
        <ModalBody className="overflow-auto">
          <Container>
            <Row className="justify-content-center">
              <Col xs={12} md={7} className="text-center">
                <img
                  src={productImgUrl}
                  alt="Generated Product"
                  className="ws-img-clickable img-fluid"
                  onClick={toggleModal}
                  style={{ maxWidth: '100%', objectFit: 'contain', borderRadius: "8px" }}
                />
              </Col>
              <Col xs={12} md={5}>
                <h3>{product?.name}</h3>
                <p className="fw-bold">{product?.catalogItemVariantName}</p>
                <span className="fw-bold">{formatCurrency(product?.price)}</span><br />
                <Button color="primary" className="mt-3 w-100" onClick={() => onAddToCart()}>
                  Add to Cart
                </Button> <br />
                {/* Instagram Share Button (Copies Link) */}
                <Button
                  title="Copy for Instagram Story"
                  onClick={() =>
                    copyToClipboard("Link copied to clipboard! Open Instagram, go to your Story, and paste the link.")
                  }
                  style={{ background: "transparent", border: "none", cursor: "pointer" }}
                >
                  <FaInstagram size={SHARE_ICON_SIZE} color="#E4405F" />
                </Button>

                <FacebookShareButton
                  url={productUrl}
                  title={product?.name}
                  hashtag="#Wallscapes"
                >
                  <Button title="Share on Facebook" style={{ background: "transparent", border: "none" }}>
                    <FaFacebook size={SHARE_ICON_SIZE} color="#1877F2" />
                  </Button>
                </FacebookShareButton>

                <TwitterShareButton
                  url={productUrl}
                  title={product?.name}
                  via="WallscapesAI"
                  hashtags={["Wallscapes", "Decor"]}
                  related={["Wallscapes"]}
                >
                  <Button title="Share on X (Twitter)" style={{ background: "transparent", border: "none" }}>
                    <FaXTwitter size={SHARE_ICON_SIZE} color="black" />
                  </Button>
                </TwitterShareButton>

                <WhatsappShareButton
                  url={productUrl}
                  title={`Check out this product: ${product?.name}`}
                  separator=" - "
                >
                  <Button title="Share on WhatsApp" style={{ background: "transparent", border: "none" }}>
                    <FaWhatsapp size={SHARE_ICON_SIZE} color="#25D366" />
                  </Button>
                </WhatsappShareButton>

                <RedditShareButton
                  url={productUrl}
                  title={product?.name}
                >
                  <Button title="Share on Reddit" style={{ background: "transparent", border: "none" }}>
                    <FaReddit size={SHARE_ICON_SIZE} color="#FF4500" />
                  </Button>
                </RedditShareButton>

                <PinterestShareButton
                  url={productUrl}
                  media={productImgUrl}
                  description={`${product?.name} - ${product?.description}`}
                >
                  <Button title="Share on Pinterest" style={{ background: "transparent", border: "none" }}>
                    <FaPinterest size={SHARE_ICON_SIZE} color="#E60023" />
                  </Button>
                </PinterestShareButton>

                {/* Custom Email Share Button using mailto */}
                <a href={mailtoLink} title="Share via Email">
                  <Button style={{ background: "transparent", border: "none" }}>
                    <FaEnvelope size={SHARE_ICON_SIZE} color="gray" />
                  </Button>
                </a>

                {/* Clipboard Copy Button with Toast */}
                <Button
                  title="Copy Link"
                  onClick={() => copyToClipboard("Link copied to clipboard!")}
                  style={{ background: "transparent", border: "none", cursor: "pointer" }}
                >
                  <FaRegCopy size={SHARE_ICON_SIZE} color="gray" />
                </Button>
                <p>{product?.catalogItemDescription}</p>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ProductDetail;
