import React, { useState } from 'react';
import { Button, Form, Input, Container, Row, Col, Modal, ModalBody } from 'reactstrap';
import useImageApi from '../../managers/ImageManager';
import useThrottler from '../../managers/ThrottleManager';
import useProductApi from '../../managers/ProductManager';
import useContentApi from '../../managers/ContentManager';
import Loading, { LoadingStates } from '../../components/Loading';
import { useAuth0 } from '@auth0/auth0-react';
import PromptIdeas from './component/PromptIdeas';

const Generate: React.FC = () => {
  const [loadingState, setLoadingState] = useState<typeof LoadingStates[keyof typeof LoadingStates]>(LoadingStates.SUCCESS);
  const [loadingMessage, setLoadingMessage] = useState("Loading");
  const [productImgUrl, setProductImgUrl] = useState('/logo512.png');
  const [isModalOpen, setModalOpen] = useState(false);

  // State for AI-generated images
  const [prompt, setPrompt] = useState('');
  const [productName, setProductName] = useState('');
  const [imageShape, setImageShape] = useState('square');
  const [imageStyle, setImageStyle] = useState('vivid');
  const [catalogItemId, setCatalogItemId] = useState('POSTER');
  const [catalogItemVariantId, setCatalogItemVariantId] = useState('POSTER-18W-18H');

  // State for uploading user images
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const { generateProduct } = useProductApi();
  const { generateImage, uploadImage } = useImageApi();
  const { user } = useAuth0();
  // const { getPhase, getTotalUsage, getPhaseLimit, getUserUsage, getUserLimit } = useThrottler();

  // Handlers for AI image generation
  const handlePromptChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrompt(event.target.value);
  };

  const handleProductNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProductName(event.target.value);
  };

  const handlePromptIdeaClick = (idea: string) => {
    setPrompt(idea);
  };

  const handleImageShapeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImageShape(event.target.value);
  };

  const handleImageStyleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImageStyle(event.target.value);
  };

  const handleCatalogItemIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCatalogItemId(event.target.value);
  };

  const handleCatalogItemVariantIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCatalogItemVariantId(event.target.value);
  };

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoadingMessage("Generating AI Image...");
    setLoadingState(LoadingStates.LOADING);
    try {
      const image = await generateImage({ userId: user?.sub || "0", action: 'generate', prompt: prompt, vendorId: "openai", imageShape: imageShape, imageStyle: imageStyle });
      setLoadingState(LoadingStates.SUCCESS);
      if (image?.imageUrl) {
        console.log('Generated image URL:', image?.imageUrl);
        setLoadingMessage("Generating Product Preview...");
        setLoadingState(LoadingStates.LOADING);
        const product = await generateProduct({ userId: user?.sub || "0", sourceImageUrl: image?.imageUrl, sourceImageId: image?.imageId, catalogItemId, catalogItemVariantId });
        if (product && Array.isArray(product.previewImageUrls) && product.previewImageUrls.length > 0) {
          const imageUrl = product.previewImageUrls[0];
          setProductImgUrl(imageUrl || '/logo256.png');
          toggleModal();
        } else {
          throw new Error('Product Preview Image Generation Failed.');
        }
        setLoadingState(LoadingStates.SUCCESS);
      } else {
        throw new Error('AI Image Generation Failed.');
      }
    } catch (error: any) {
      setLoadingState(LoadingStates.ERROR);
      setLoadingMessage(error?.message);
      console.error(error);
    }
  };

  // Handlers for user image upload
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    setSelectedFile(file);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert("Please select a file to upload.");
      return;
    }

    const reader = new FileReader();
    reader.onload = async () => {
      try {
        setLoadingMessage("Uploading User Image...");
        setLoadingState(LoadingStates.LOADING);

        const base64Image = (reader.result as string).split(',')[1]; // Extract base64 content
        const image = await uploadImage({ userId: user?.sub || "0", action: 'upload', imageName: productName, prompt: prompt, base64Image });
        setLoadingState(LoadingStates.SUCCESS);
        console.log("Uploaded user image:", image);

        if (image?.imageUrl) {
          console.log('Generated image URL:', image?.imageUrl);
          setLoadingMessage("Generating Product Preview...");
          setLoadingState(LoadingStates.LOADING);
          const product = await generateProduct({ userId: user?.sub || "0", sourceImageUrl: image?.imageUrl, sourceImageId: image?.imageId, catalogItemId, catalogItemVariantId });
          if (product && Array.isArray(product.previewImageUrls) && product.previewImageUrls.length > 0) {
            const imageUrl = product.previewImageUrls[0];
            setProductImgUrl(imageUrl || '/logo256.png');
            toggleModal();
          } else {
            throw new Error('Product Preview Image Generation Failed.');
          }
          setLoadingState(LoadingStates.SUCCESS);
        } else {
          throw new Error('Image Upload Failed.');
        }

        // Optionally, display the uploaded image
        setProductImgUrl(image.imageUrl);
        toggleModal();
      } catch (error: any) {
        setLoadingState(LoadingStates.ERROR);
        setLoadingMessage(error?.message);
        console.error("Error uploading user image:", error);
      }
    };
    reader.readAsDataURL(selectedFile);
  };

  return (
    <Container className="h-100 d-flex justify-content-center align-items-center">
      <Row>
        <Col md="6" className="text-center w-100">
          <h1>Generate your own Wallscape!</h1>
          <p>Type in the description of what you would like your wallscape to look like. You are only limited by your imagination (and AI)!</p>

          {/* Display Prompt Ideas */}
          <PromptIdeas onPromptSelect={handlePromptIdeaClick} />

          {/* Form for AI Image Generation */}
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md="6" className="m-0 p-0">
                <Input type="select" value={catalogItemId} onChange={handleCatalogItemIdChange}>
                  <option value="WALLFLAG">Wall Flag</option>
                  <option value="POSTER">Poster</option>
                  <option value="MOUSEPAD">Mouse Pad</option>
                </Input>
              </Col>
              <Col md="6" className="m-0 p-0">
                <Input type="select" value={catalogItemVariantId} onChange={handleCatalogItemVariantIdChange}>
                  <option value="WALLFLAG-36W-26H">Wall Flag - 36x26 (Horizontal)</option>
                  <option value="WALLFLAG-26W-36H">Wall Flag - 26x36 (Vertical)</option>
                  <option value="POSTER-12W-12H">Poster - 12x12</option>
                  <option value="POSTER-18W-18H">Poster - 18x18</option>
                  <option value="POSTER-24W-24H">Poster - 24x24</option>
                  <option value="MOUSEPAD-GENERIC">Mouse Pad</option>
                </Input>
              </Col>
            </Row>
            <Row>
              <Col md="8" className="m-0 p-0">
                <Input id='prompt' name='prompt' type='text' value={prompt} onChange={handlePromptChange} />
              </Col>
              <Col md="2" className="m-0 p-0">
                <Input type="select" value={imageShape} onChange={handleImageShapeChange}>
                  <option value="square">Square</option>
                  <option value="horizontal">Horizontal</option>
                  <option value="vertical">Vertical</option>
                </Input>
              </Col>
              <Col md="2" className="m-0 p-0">
                <Input type="select" value={imageStyle} onChange={handleImageStyleChange}>
                  <option value="vivid">Vivid</option>
                  <option value="natural">Natural</option>
                </Input>
              </Col>
            </Row>
            <Button className="w-100" type='submit'>Generate</Button>
          </Form>

          <hr />

          {/* Section for User Image Upload */}
          <h2>Or Upload Your Own Image!</h2>
          <Form>
            <Row>
              <Col md="2" className="m-0 p-0">
                <Input type="select" value={catalogItemId} onChange={handleCatalogItemIdChange}>
                  <option value="WALLFLAG">Wall Flag</option>
                  <option value="POSTER">Poster</option>
                  <option value="MOUSEPAD">Mouse Pad</option>
                </Input>
                <Input type="select" value={catalogItemVariantId} onChange={handleCatalogItemVariantIdChange}>
                  <option value="WALLFLAG-36W-26H">Wall Flag - 36x26 (Horizontal)</option>
                  <option value="WALLFLAG-26W-36H">Wall Flag - 26x36 (Vertical)</option>
                  <option value="POSTER-12W-12H">Poster - 12x12</option>
                  <option value="POSTER-18W-18H">Poster - 18x18</option>
                  <option value="POSTER-24W-24H">Poster - 24x24</option>
                  <option value="MOUSEPAD-GENERIC">Mouse Pad</option>
                </Input>
              </Col>
              <Col md="8" className="m-0 p-0">
                <Input type="file" onChange={handleFileChange} />
              </Col>
              <Col md="2" className="m-0 p-0">
                <Button className="w-100" onClick={handleUpload}>Upload</Button>
              </Col>
            </Row>
            <Row>
              <Col md="4" className="m-0 p-0">
                <Input id='productName' name='productName' type='text' placeholder={"Enter Product Name"} value={productName} onChange={handleProductNameChange} />
              </Col>
            </Row>
            {/*
              <Col md="4" className="m-0 p-0">
                <Input id='prompt' name='prompt' type='text' placeholder={"Enter Modification Prompt"} value={prompt} onChange={handlePromptChange} />
              </Col>
              <Col md="2" className="m-0 p-0">
                <Input type="select" value={imageShape} onChange={handleImageShapeChange}>
                  <option value="square">Square</option>
                  <option value="horizontal">Horizontal</option>
                  <option value="vertical">Vertical</option>
                </Input>
              </Col>
              <Col md="2" className="m-0 p-0">
                <Input type="select" value={imageStyle} onChange={handleImageStyleChange}>
                  <option value="vivid">Vivid</option>
                  <option value="natural">Natural</option>
                </Input>
              </Col>
            </Row>
              */}
          </Form>

          {(loadingState === LoadingStates.LOADING || loadingState === LoadingStates.ERROR) && (
            <div><Loading state={loadingState} message={loadingMessage} /></div>
          )}
          <br />

          {/* Modal to display product or uploaded image */}
          <Modal isOpen={isModalOpen} toggle={toggleModal} className="modal-fullscreen">
            <ModalBody className="d-flex align-items-center justify-content-center">
              <img src={productImgUrl} alt='Uploaded/Generated Product' className='img-fluid ws-img-clickable' />
              <Button color="secondary" className="position-absolute top-0 end-0 m-2" onClick={toggleModal}>Close</Button>
            </ModalBody>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
};

export default Generate;
